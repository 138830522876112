<template>
  <div id="register">
    <div class="shadow"></div>
    <div class="tz">
      <img src="@/assets/images/tzzm.png" alt="" v-if="regimentalCommander" />
      <div class="content">
        <div class="message" v-if="regimentalCommander">
          <svg-icon icon-class="message" />欢迎注册正品汇店长
        </div>
        <div class="steps" v-else>
          <div class="state">
            <span></span>
            <span>1</span>
            <p>填写信息</p>
          </div>
          <div>
            <span></span>
            <span>2</span>
            <p>注册成功</p>
          </div>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item prop="number" label="手机号码">
            <el-input
              type="text"
              v-model="ruleForm.number"
              placeholder="请输入手机号码"
              maxlength="11"
              :validate-event="false"
            ></el-input>
          </el-form-item>
          <el-form-item prop="authCode" label="手机验证码">
            <el-input
              type="text"
              v-model="ruleForm.authCode"
              placeholder="请输入手机验证码"
              maxlength="6"
              :validate-event="false"
            >
              <template #append>
                <p
                  @click="CaptchaMethod(false)"
                  :style="Captcha ? 'cursor: default;' : ''"
                >
                  {{ Captcha ? Captcha : "获取验证码" }}
                </p>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="pass" label="设置密码">
            <el-input
              type="password"
              v-model="ruleForm.pass"
              placeholder="请输入密码"
              :validate-event="false"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item prop="checkPass" label="确认密码">
            <el-input
              type="password"
              v-model="ruleForm.checkPass"
              placeholder="请再次输入密码"
              :validate-event="false"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="推荐人手机号(非必填)" v-if="regimentalCommander">
            <el-input
              type="text"
              v-model="ruleForm.number2"
              placeholder="请输入推荐人手机号"
              maxlength="11"
              :disabled="referral"
              :validate-event="false"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >立即注册</el-button
            >
          </el-form-item>
        </el-form>
        <div class="clause">
          <input type="checkbox" v-model="clause" />
          <div>
            我已阅读并接受以下条款：<a
              href="/Agreement/TermsOfService.pdf"
              target="_blank"
              >《服务条款》</a
            ><a href="/Agreement/PrivacyPolicy.pdf" target="_blank"
              >《隐私政策》</a
            ><a href="/Agreement/UserServiceAgreement.pdf" target="_blank"
              >《用户服务协议》</a
            >
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="tipsShow"
      width="400px"
      center
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :show-close="false"
      :lock-scroll="false"
    >
      <div class="message">
        <svg-icon icon-class="message" />你已注册账号，请验证手机号获取店长身份
      </div>
      <el-form
        :model="ruleForm2"
        :rules="rules2"
        ref="ruleForm2"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item prop="number" label="手机号：">
          <el-input
            type="text"
            v-model="ruleForm2.number"
            placeholder="请输入注册手机号"
            maxlength="11"
            :validate-event="false"
          ></el-input>
        </el-form-item>
        <el-form-item prop="authCode" label="验证码：">
          <el-input
            type="text"
            v-model="ruleForm2.authCode"
            placeholder="请输入验证码"
            maxlength="6"
            :validate-event="false"
          >
            <template #append>
              <p
                @click="CaptchaMethod(true)"
                :style="Captcha2 ? 'cursor: default;' : 'cursor: pointer;'"
              >
                {{ Captcha2 ? Captcha2 : "获取验证码" }}
              </p>
            </template>
          </el-input>
        </el-form-item>

        <!-- <el-button type="primary" @click="submitForm('ruleForm')"
            >确认</el-button
          > -->
        <el-button type="primary" @click="submitForm('ruleForm2')"
          >确认</el-button
        >
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tipsShow = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { getToken, setToken, removeToken } from "@/utils/auth";
export default {
  name: "register",

  data() {
    var validateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号码不能为空"));
      } else if (
        !new RegExp(
          /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
        ).test(value)
      ) {
        return callback(new Error("手机号码格式错误"));
      } else {
        callback();
      }
    };
    var validateauthCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机验证码不能为空"));
      } else {
        if (value === "test") {
          callback();
        } else {
          this.$API.personalUser
            .verificationCAPTCHA({
              mobile: this.ruleForm.number,
              captcha: this.ruleForm.authCode,
            })
            .then((res) => {
              if (!res.data.data.result) {
                return callback(new Error("验证码错误"));
              } else {
                callback();
              }
            });
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6) {
        callback(new Error("密码最少为6位"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validateNumber2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号码不能为空"));
      } else if (
        !new RegExp(
          /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
        ).test(value)
      ) {
        return callback(new Error("手机号码格式错误"));
      } else if (this.ruleForm2.authCode) {
        if (value != this.user.userEntity.telNum) {
          return callback(new Error("注册手机号错误"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validateauthCode2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机验证码不能为空"));
      } else {
        if (value === "test") {
          callback();
        } else {
          this.$API.personalUser
            .verificationCAPTCHA({
              mobile: this.ruleForm2.number,
              captcha: this.ruleForm2.authCode,
            })
            .then((res) => {
              if (!res.data.data.result) {
                return callback(new Error("验证码错误"));
              } else {
                callback();
              }
            });
        }
      }
    };
    // var validateNumber3 = (rule, value, callback) => {
    //   if (
    //     !new RegExp(
    //       /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
    //     ).test(value)
    //   ) {
    //     return callback(new Error("手机号码格式错误"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      ruleForm: {
        number: "",
        authCode: "",
        pass: "",
        checkPass: "",
        number2: "",
      },
      ruleForm2: {
        number: "",
        authCode: "",
      },
      rules: {
        number: [{ validator: validateNumber, trigger: "blur" }],
        authCode: [{ validator: validateauthCode, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        // number2: [{ validator: validateNumber3, required: false, }],
      },
      rules2: {
        number: [{ validator: validateNumber2, trigger: "blur" }],
        authCode: [{ validator: validateauthCode2, trigger: "blur" }],
      },
      Captcha: false, //验证码倒计时
      Captcha2: false, //验证码倒计时
      clause: false,
      regimentalCommander: 0,
      tipsShow: false,
      user: "",
      referral: false,
    };
  },
  created() {
    this.$route.name === "to-register" && (this.regimentalCommander = 1);

    if (this.$route.query.phoneNumber) {
      sessionStorage.setItem("referral", this.$route.query.phoneNumber);
      this.$router.replace("/Login/ToRegister");
    }

    if (sessionStorage.getItem("referral")) {
      this.ruleForm.number2 = sessionStorage.getItem("referral");
      this.referral = true;
    }
  undefined
    // this.ruleForm.number2 = this.$route.query.phoneNumber;
    
    sessionStorage.getItem("userType") === "CONSUMER" && (this.tipsShow = true);
    sessionStorage.getItem("userType") === "PURCHASE" &&
      this.$alert("恭喜您，注册成功！<br/>下一步，请完善店长资料", "提示", {
        confirmButtonText: "立即完善资料",
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          this.$router.push("PerfectInformation");
        },
      });
  },
  beforeDestroy() {},
  methods: {
    submitForm(name) {
      return
      if (name === "ruleForm") {
        if (this.clause) {
          this.$refs.ruleForm.validate((valid) => {
            if (valid) {
              this.registerUser();
            } else {
              return false;
            }
          });
        } else {
          this.$message({
            message: "请勾选服务条款",
            type: "warning",
            duration: 1500,
            offset: 420,
          });
        }
      } else {
        this.$refs.ruleForm2.validate((valid) => {
          if (valid) {
            this.updateUserType();
          } else {
            return false;
          }
        });
      }
    },
    async CaptchaMethod(flag) {
      if (flag) {
        this.user === "" && (await this.getUserData());
        this.$refs.ruleForm2.validateField(["number"], async (valid) => {
          if (!valid && !this.Captcha2) {
            await this.$API.personalUser
              .sendCAPTCHA({
                mobile: this.ruleForm2.number,
              })
              .then((res) => {
                this.$message({
                  message: "发送成功",
                  type: "success",
                  offset: 420,
                });
                let t = 59;
                this.Captcha2 = `${t}s后重新获取`;
                this.t = setInterval(() => {
                  t--;
                  this.Captcha2 = `${t}s后重新获取`;
                  if (t < 1) {
                    clearInterval(this.t);
                    this.Captcha2 = false;
                  }
                }, 1000);
              })
              .catch(() => {
                this.$message({
                  message: "发送失败",
                  type: "error",
                  offset: 420,
                });
              });
          }
        });
      } else {
        this.$refs.ruleForm.validateField(["number"], async (valid) => {
          if (!valid && !this.Captcha) {
            await this.$API.personalUser
              .sendCAPTCHA({
                mobile: this.ruleForm.number,
              })
              .then((res) => {
                this.$message({
                  message: "发送成功",
                  type: "success",
                  offset: 420,
                });
                let t = 59;
                this.Captcha = `${t}s后重新获取`;
                this.t = setInterval(() => {
                  t--;
                  this.Captcha = `${t}s后重新获取`;
                  if (t < 1) {
                    clearInterval(this.t);
                    this.Captcha = false;
                  }
                }, 1000);
              })
              .catch(() => {
                this.$message({
                  message: "发送失败",
                  type: "error",
                  offset: 420,
                });
              });
          }
        });
      }
    },
    async registerUser() {
      await this.$API.login
        .register({
          object: {
            captcha: this.ruleForm.authCode,
            password: this.ruleForm.checkPass,
            telNum: this.ruleForm.number,
            userType: this.regimentalCommander ? "PURCHASE" : "CONSUMER",
            referrerTelNum: this.ruleForm.number2,
            // ENTERPRISE, 企业
            // PLATFORM, 平台
            // CONSUMER, 消费者
            // PURCHASE, 店长
          },
        })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            if (this.regimentalCommander) {
              sessionStorage.setItem("userId", res.data.data.id);
              this.loginAPI();
              this.$alert(
                "恭喜您，注册成功！<br/>下一步，请完善店长资料",
                "提示",
                {
                  confirmButtonText: "立即完善资料",
                  dangerouslyUseHTMLString: true,
                  callback: (action) => {
                    this.$router.push("PerfectInformation");
                  },
                }
              );
            } else {
              this.$message({
                message: "注册成功",
                type: "success",
                offset: 420,
              });
              this.$router.push("/Login");
            }
          } else if (res.data.code === "UNIQUE_VALIDATE") {
            // this.$message({
            //   message: "该账号已注册",
            //   type: "error",
            //   offset: 420,
            // });
            this.$confirm("该账号已注册，是否去登录？", "提示", {
              confirmButtonText: "去登录",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push("/Login");
              })
              .catch(() => {});
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              offset: 420,
            });
          }
        });
    },
    async loginAPI() {
      const data = await this.$API.login.login({
        password: this.ruleForm.checkPass,
        username: this.ruleForm.number,
      });
      if (data.data.code === "SUCCESS") {
        setToken(data.data.data.token);
      } else {
        this.$message({
          type: "error",
          message: data.data.msg,
          offset: 420,
          duration: 1500,
        });
      }
      // USER_NOT_FOUND 用户未找到
      // BAD_CREDENTIALS 密码错误
      // SUCCESS 成功
    },
    async getUserData() {
      const data = await this.$API.personalUser.getUserData({
        object: {
          id: sessionStorage.getItem("userId"),
        },
      });
      this.user = data.data.data.userInfo;
    },
    async updateUserType() {
      await this.$API.personalUser
        .updateUserType({
          object: "PURCHASE",
        })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            sessionStorage.setItem("userType", "PURCHASE");
            this.tipsShow = false;
            this.$message({
              message: "注册成功",
              type: "success",
              offset: 420,
            });
            this.$alert("恭喜！你已获得店长身份，请重新登录！", "提示", {
              confirmButtonText: "去登录",
              dangerouslyUseHTMLString: true,
              callback: (action) => {
                sessionStorage.removeItem("userId");
                sessionStorage.removeItem("userType");
                removeToken();
                this.$router.push("/Login");
              },
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#register {
  // margin-top: 12px;
  background-color: #fff;
  padding: 80px 0;
  position: relative;
  .shadow {
    position: absolute;
    height: 10px;
    top: -9px;
    left: 0;
    right: 0;
    z-index: 0;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.08);
  }
  .tz {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    .content {
      width: 400px;

      .steps {
        display: flex;
        margin-bottom: 40px;
        div {
          flex: 1;
          text-align: center;
          position: relative;
          span:nth-child(1) {
            display: block;
            width: 200px;
            height: 5px;
            background: #cccccc;
          }
          span:nth-child(2) {
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #cccccc;
            font-size: 12px;
            color: #ffffff;
            line-height: 24px;
            position: absolute;
            top: -9.5px;
            left: 50%;
            margin-left: -12px;
          }
          p {
            margin-top: 17px;
            height: 16px;
            font-size: 12px;
            color: #cccccc;
          }
        }
        .state {
          span {
            background: #33bb44 !important;
          }
          p {
            color: #33bb44;
          }
        }
      }
      .message {
        width: 400px;
        height: 44px;
        background: #fff0de;
        border: 1px solid #ffe5c2;
        padding: 0 16px;
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 21px;
        .svg-icon {
          margin-right: 8px;
          font-size: 20px;
        }
      }
      ::v-deep .el-form {
        .el-form-item {
          margin-bottom: 20px;
          position: relative;
          .el-form-item__label {
            position: absolute;
            z-index: 100;
            top: 0;
            left: 1px;
            bottom: 0;
            line-height: 16px;
            text-align: start;
            padding-left: 15px;
            color: #333333;
            display: flex;
            align-items: center;
          }
          .el-form-item__content {
            margin-left: 0 !important;
            .el-input {
              input {
                height: 44px;
                border-radius: 0;
                padding-left: 100px;
              }
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }

              input[type="number"] {
                -moz-appearance: textfield;
              }
              .el-input-group__append {
                font-size: 12px;
                color: #333333;
                line-height: 18px;
                border-radius: 0;
                padding: 0;
                p {
                  width: 102px;
                  height: 42px;
                  line-height: 42px;
                  text-align: center;
                  cursor: pointer;
                }
              }
            }
            .el-button {
              width: 100%;
              border-radius: 0;
              background: linear-gradient(132deg, #ff7300 0%, #fb5d20 100%);
            }
          }
        }
        .el-form-item:last-child {
          margin-bottom: 12px;
        }
      }
      .clause {
        font-size: 12px;
        display: flex;
        input[type="checkbox"] {
          margin: 2px;
        }
        div {
          line-height: 17px;
          color: #333333;
          margin-left: 8px;
          a {
            color: #208cf8;
          }
          a:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  ::v-deep .el-dialog__wrapper {
    position: absolute;
    .el-dialog__header {
      padding-bottom: 20px;
      .el-dialog__title {
        font-weight: 600;
      }
    }
    .el-dialog__body {
      padding-top: 0;
      .message {
        height: 44px;
        background: #fff0de;
        border: 1px solid #ffe5c2;
        padding: 0 16px;
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 21px;
        .svg-icon {
          margin-right: 8px;
          font-size: 20px;
        }
      }
      .el-form {
        .el-form-item__label {
          text-align: start;
        }
        .el-input__inner {
          border-radius: 0;
        }
        .el-input-group__append {
          padding: 0;
          border-radius: 0;
          color: #333333;
          font-size: 12px;
          p {
            width: 102px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            cursor: pointer;
          }
        }
        .el-input {
          input {
            height: 44px;
            border-radius: 0;
          }
        }
        .el-button {
          width: 100%;
          border-radius: 0;
        }
      }
    }
  }
  ::v-deep .v-modal {
    position: absolute;
  }
}
</style>